export enum IDENTITIES_DEFAULT_FIELDS {
  AGENT_CODE = 'Agent Code',
  NIC_NUMBER = 'National Identity Card (NIC) Number',
  PHONE_NUMBER = 'Contact Number (Type)',
  APPOINTMENT_DATE = 'Appointment Date',
  INITIAL_APPOINTMENT_DATE = 'Initial Appointment Date',
  BASIC_SALARY = 'Basic Salary',
  BUSINESS_DEVELOPMENT_GRANT = 'Business Development Grant',
  DESIGNATION_CODE = 'Designation Code',
  BRANCH = 'Branch',
  REGION = 'Region',
  ZONE = 'Zone',
  DESIGNATION = 'Designation',
  POSITION = 'Position',
  BANK = 'Bank',
  BANK_BRANCH = 'Bank Branch',
  ACCOUNT_NUMBER = 'Account Number',
  ACCOUNT_HOLDER = 'Account Holder',
  TRAVEL_REIMBURSEMENT = 'Travel Reimbursement',
  EMPLOYMENT_TYPE = 'Employment Type',

  // Don't define item(s) below in apps/api/src/modules/constants/mandatory-fields/identities.ts
  WORKING_MONTH = 'Working Month',
}
export enum CONFIGURABLE_FIELD_DATA_KEYS {
  AGENT_CODE = 'AGENT_CODE',
  NIC = 'NIC',
}
