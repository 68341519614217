import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  CommonResponseDTO,
  IAchievementConfig,
  IAchievementConfigRequest,
  IAchievementLockDateConfigRequest,
  IAchievementLockDateConfigResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

interface AchievementSheetConfigsResponse {
  data: IAchievementConfig[];
  message: string;
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AchievementConfigsService {
  private _configurations = new BehaviorSubject<IAchievementConfig[]>([]);
  constructor(private http: HttpClient) {}

  public async getAchievementSheetConfigs(): Promise<IAchievementConfig[]> {
    const url = generateURL({
      endpoint: ENDPOINTS.ACHIEVEMENTS_CONFIG_GET_ALL,
    });
    const { data } = await firstValueFrom(
      this.http.get<AchievementSheetConfigsResponse>(url)
    );

    this._configurations.next(data);
    return data;
  }

  public setAchievementSheetConfigs(
    fields: IAchievementConfigRequest[]
  ): Observable<AchievementSheetConfigsResponse> {
    const url = generateURL({ endpoint: ENDPOINTS.ACHIEVEMENTS_CONFIG_UPDATE });
    return this.http
      .post<AchievementSheetConfigsResponse>(url, { fields })
      .pipe(
        tap((response) => {
          this._configurations.next(response.data);
        })
      );
  }

  public setTargetsForAchievementSheetConfigs(
    fields: IAchievementConfigRequest[]
  ): Observable<AchievementSheetConfigsResponse> {
    const url = generateURL({
      endpoint: ENDPOINTS.ACHIEVEMENTS_CONFIG_UPDATE_TARGET,
    });
    return this.http
      .post<AchievementSheetConfigsResponse>(url, { fields })
      .pipe(
        tap((response) => {
          this._configurations.next(response.data);
        })
      );
  }

  public setLockDateConfig(
    config: IAchievementLockDateConfigRequest
  ): Observable<CommonResponseDTO<IAchievementLockDateConfigResponse>> {
    const url = generateURL({
      endpoint: ENDPOINTS.ACHIEVEMENTS_LOCK_DATE_CONFIG_UPDATE,
    });
    return this.http.post<
      CommonResponseDTO<IAchievementLockDateConfigResponse>
    >(url, config);
  }

  public async getLockDateConfig(): Promise<IAchievementLockDateConfigResponse> {
    const url = generateURL({
      endpoint: ENDPOINTS.ACHIEVEMENTS_LOCK_DATE_CONFIG_GET,
    });

    const data = await firstValueFrom(
      this.http.get<CommonResponseDTO<IAchievementLockDateConfigResponse>>(url)
    );
    return data.data;
  }
}
