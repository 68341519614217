import { ENVIRONMENTS, IWebEnvironment } from '@shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IWebEnvironment = {
  env: ENVIRONMENTS.LOCAL,
  production: true,
  isDebugMode: true,
  isSentryDebugMode: false,
  VERSION: require('../../../../package.json').version,
  CORE_VERSION: require('../../../../package.json').coreversion,
  API_URL: 'https://qa.propel.echonlabs.com/api',
  WEB_URL: 'https://qa.propel.echonlabs.com',
  WEB_DOMAIN: 'qa.propel.echonlabs.com',
  API_DOMAIN: 'qa.propel.echonlabs.com',
  SENTRY_DSN: process.env.NX_WEB_SENTRY_DSN,
};
