import { FORMULA_ELEMENT_TYPES } from '@shared/constants';
import {
  IFormulaAchievementElement,
  IFormulaConstantElement,
  IFormulaCursorElement,
  IFormulaCustomVariableElement,
  IFormulaDataReferenceElement,
  IFormulaElement,
  IFormulaExternalDataElement,
  IFormulaFileDataElement,
  IFormulaIntegrationMappingElement,
  IFormulaOperatorElement,
  IFormulaProductElement,
  IFormulaReferenceElement,
  IFormulaTargetElement,
} from '@shared/interfaces';

export const cursorFormulaElement = (
  element: IFormulaElement
): IFormulaCursorElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.CURSOR) {
    return element;
  }
  return null;
};

export const operatorFormulaElement = (
  element: IFormulaElement
): IFormulaOperatorElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.OPERATOR) {
    return element;
  }
  return null;
};

export const constantFormulaElement = (
  element: IFormulaElement
): IFormulaConstantElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.CONSTANT) {
    return element;
  }
  return null;
};

export const customVariableFormulaElement = (
  element: IFormulaElement
): IFormulaCustomVariableElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.CUSTOM_VARIABLE) {
    return element;
  }
  return null;
};

export const integrationFormulaElement = (
  element: IFormulaElement
): IFormulaIntegrationMappingElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.INTEGRATION_MAPPING) {
    return element;
  }
  return null;
};

export const externalDataFormulaElement = (
  element: IFormulaElement
): IFormulaExternalDataElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.EXTERNAL_DATA) {
    return element;
  }
  return null;
};

export const dataReferenceFormulaElement = (
  element: IFormulaElement
): IFormulaDataReferenceElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.DATA_REFERENCE) {
    return element;
  }
  return null;
};

export const referenceFormulaElement = (
  element: IFormulaElement
): IFormulaReferenceElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.REFERENCE) {
    return element;
  }
  return null;
};

export const fileDataFormulaElement = (
  element: IFormulaElement
): IFormulaFileDataElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.FILE_DATA) {
    return element;
  }
  return null;
};

export const productFormulaElement = (
  element: IFormulaElement
): IFormulaProductElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.PRODUCT) {
    return element;
  }
  return null;
};
export const targetFormulaElement = (
  element: IFormulaElement
): IFormulaTargetElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.TARGET) {
    return element;
  }
  return null;
};

export const achievementFormulaElement = (
  element: IFormulaElement
): IFormulaAchievementElement | null => {
  if (element?.type === FORMULA_ELEMENT_TYPES.ACHIEVEMENT) {
    return element;
  }
  return null;
};
